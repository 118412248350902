<template>
  <card-block>
    <card-header>
      Отчёты
    </card-header>
    <card-body>
      <div class="accordion">
        <div class="card" v-if="can('module.report.client-view')">
          <div class="card-header">
            <h2 class="mb-0">
              <button class="btn btn-link btn-block text-left" @click.prevent="$refs.clientReport.openModal()">
                Отчёт по клиентам
              </button>
            </h2>
          </div>
        </div>
        <div class="card" v-if="can('module.report.subscription_chain-view')">
          <div class="card-header">
            <h2 class="mb-0">
              <button class="btn btn-link btn-block text-left"
                      @click.prevent="$refs.subscriptionChainReport.openModal()">
                Отчёт по цепочкам подписок
              </button>
            </h2>
          </div>
        </div>
        <div class="card" v-if="can('module.report.squad-view')">
          <div class="card-header">
            <h2 class="mb-0">
              <button class="btn btn-link btn-block text-left" @click.prevent="$refs.squadReport.openModal()">
                Отчёт по отрядам
              </button>
            </h2>
          </div>
        </div>
        <div class="card" v-if="can('module.report.client_call-view')">
          <div class="card-header">
            <h2 class="mb-0">
              <button class="btn btn-link btn-block text-left" @click.prevent="$refs.clientCallReport.openModal()">
                Отчёт по вызовам
              </button>
            </h2>
          </div>
        </div>
        <div class="card" v-if="can('module.report.payment_detail-view')">
          <div class="card-header">
            <h2 class="mb-0">
              <button class="btn btn-link btn-block text-left" @click.prevent="$refs.paymentDetailReport.openModal()">
                Отчёт по платежам детальный
              </button>
            </h2>
          </div>
        </div>
        <div class="card" v-if="can('module.report.payment-view')">
          <div class="card-header">
            <h2 class="mb-0">
              <button class="btn btn-link btn-block text-left" @click.prevent="$refs.paymentReport.openModal()">
                Отчёт по платежам
              </button>
            </h2>
          </div>
        </div>
      </div>

      <client-report-modal ref="clientReport"/>
      <subscription-chain-report-modal ref="subscriptionChainReport"/>
      <squad-report-modal ref="squadReport"/>
      <client-call-report-modal ref="clientCallReport"/>
      <payment-detail-report-modal ref="paymentDetailReport"/>
      <payment-report-modal ref="paymentReport"/>

    </card-body>
  </card-block>
</template>

<script>

import ClientReportModal from "@/views/Report/ClientReportModal";
import SquadReportModal from "@/views/Report/SquadReportModal";
import ClientCallReportModal from "@/views/Report/ClientCallReportModal";
import PaymentDetailReportModal from "@/views/Report/PaymentDetailReportModal";
import PaymentReportModal from "@/views/Report/PaymentReportModal";
import SubscriptionChainReportModal from "@/views/Report/SubscriptionChainReportModal.vue";

export default {
  components: {
    SubscriptionChainReportModal,
    PaymentReportModal,
    PaymentDetailReportModal,
    ClientCallReportModal,
    SquadReportModal,
    ClientReportModal
  },
  data: function () {
    return {}
  },
  props: {},
  computed: {},
  beforeMount() {
  },
  mounted() {
  },
  methods: {}
}
</script>
