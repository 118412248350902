<template>
  <card-block>
    <spinner-block v-model="dataProgress">
      <template v-if="data">
        <card-header>
          <router-link :to="{name: 'payment.list'}">Платежи</router-link><!--
                    -->:
          <span class="text-secondary">#{{ data.id }}</span>
          {{ data.name }}
        </card-header>
        <div class="m-3">

          <div class="row">
            <div class="col-12 col-md-6">
              <card-field title="Статус">
                {{ data.tinkoff_payment_status ? data.tinkoff_payment_status.name : '—' }}

              </card-field>

              <card-field :modelValue="data.amount" title="Сумма, коп"/>


            </div>
            <div class="col-12 col-md-6">

              <card-field title="Клиент">
                <router-link :to="{name: 'client.detail', params: {id: data.client_id}}">
                  #{{ data.client_id }} {{ data.client ? data.client.initials : '—' }}
                </router-link>
              </card-field>

              <card-field title="Вызов" v-if="data.client_call">
                <router-link :to="{name: 'client-call.detail', params: {id: data.client_call.id}}">
                  #{{ data.client_call.id }}
                </router-link>
              </card-field>

              <card-field title="Отряд" v-if="data.squad">
                <router-link :to="{name: 'squad.detail', params: {id: data.squad.id}}">
                  #{{ data.squad.id }} {{ data.squad ? data.squad.name : '—' }}
                </router-link>
              </card-field>

              <card-field title="Организация" v-if="data.organization">
                <router-link :to="{name: 'organization.detail', params: {id: data.organization.id}}">
                  #{{ data.organization.id }} {{ data.organization ? data.organization.name : '—' }}
                </router-link>
              </card-field>

              <card-field title="Подписка" v-if="data.subscription_id">
                <router-link :to="{name: 'subscription.detail', params: {id: data.subscription_id}}">
                  #{{ data.subscription_id }}
                </router-link>
              </card-field>

            </div>
            <div class="col-12 text-right">

              <button class="btn btn-primary ml-2" @click.prevent="updatePayment()">
                Обновить платёж
              </button>

              <button class="btn btn-warning ml-2" @click.prevent="cacnelPayment()" v-if="can('module.payment.update')"
                      :disabled="data.tinkoff_payment_status.code !== 'CONFIRMED' && data.tinkoff_payment_status.code !== 'PARTIAL_REFUNDED' && data.tinkoff_payment_status.code !== 'succeeded'">
                Отменить платёж
              </button>

            </div>

          </div>


        </div>
        <tab-list>
          <tab-link to="payment.detail">Основные данные</tab-link>
          <tab-link to="payment.history" v-if="can('module.payment.history-view')">История</tab-link>
          <tab-link to="payment.receipt">Данные чека</tab-link>
        </tab-list>
        <card-body>
          <router-view :key="id" :data="data" @change="getData"></router-view>
        </card-body>
      </template>
    </spinner-block>
  </card-block>
</template>

<script>
export default {
  data: function () {
    return {
      data: null,
      dataProgress: true,
    }
  },
  props: {},
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.id) {
        axiosGetValue('/api/admin/payment/get', {id: this.id}, e => this.data = e, e => this.dataProgress = e)
      }
    },
    updatePayment() {
      if (this.id) {
        axiosGetValue('/api/admin/payment/update', {id: this.id}, () => {
          this.data = null
          this.getData()
        }, e => this.dataProgress = e)
      }
    },
    cacnelPayment() {
      if (this.id) {
        $.alertConfirmForce({
          content: 'Списанные деньги будут возвращены клиенту и чек будет закрыт, это дейтсвие нельзя отменить. Для продолжения введите слово "Подтверждаю" в поле ниже.'
        }, {
          ok: () => {
            axiosGetValue('/api/admin/payment/cancel', {id: this.id}, () => {
              this.data = null
              this.getData()
            }, e => this.dataProgress = e)
          }
        });
      }
    },
  },
}
</script>